import * as React from "react";
import Layout from "../components/layout";
// import * as sections from "../components/sections"
// import Fallback from "../components/fallback"
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../LinkTranslated";
import SimpleLocalize from "../SimpleLocalize";
import Preloader from "../components/preloader";
import { PageBanner } from "../components/sections/PageBanner";
import { PageNavBar } from "../components/sections/pageNavBar";
import { ValuePropsHome } from "../components/sections/ValuePropsHome";
import { ServiceFeatures } from "../components/sections/ServiceFeatures";
import { HowWeWork } from "../components/sections/HowWeWork";
import { PriceTable } from "../components/sections/PriceTable";
import { Cta } from "../components/sections/Cta";
import { ValueProposition } from "../components/sections/ValueProposition";

export default function Disposal(props) {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "/js/slick-animation.min.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  return (
    <SimpleLocalize {...props}>
      <Layout>
        <LinkTranslated href="/">
          {/* <FormattedMessage id="header--service-1" /> */}
          {/* <PageBanner textId="header--service-1" imagePath="/img/page-banner.jpg"/> */}
        </LinkTranslated>
        <PageBanner
          textId="header--service-2"
          imagePath="/img/folders.jpg"
        />
        <PageNavBar
          fromId="header--home"
          currentId="header--service-2"
        ></PageNavBar>
        <section class="about-us-wrapper section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 text-center text-lg-start" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div class="about-img" style={{display:'flex', justifyContent:'center'}}>
                        <img src="/img/recycle.png" alt=""/>
                    </div>
                </div>
                <div class="col-lg-7 mt-5 mt-lg-0 ps-lg-5">
                    <div class="block-contents">
                        <div class="section-title">
                            <h5 class="textleft relative">Mega</h5>
                            <span><FormattedMessage id="header--service-2" /></span>
                            {/* <h2><FormattedMessage id="value--title-1" /><span><FormattedMessage id="value--title-2" /></span></h2> */}
                        </div>
                    </div>
                    <div class="about-info-contents">
                        <p><FormattedMessage id="service2--story-1" />
                        </p>
                        {/* <p><FormattedMessage id="service1--story-2" /></p> */}
                    </div>
                    {/* <div class="company-ceo d-flex align-items-center">
                        <div class="ceo-img bg-cover" style={{backgroundImage: "url('assets/img/man-img.jpg')"}}></div>
                        <div class="ceo-info">
                            <h5>Alexis D. Denzol</h5>
                            <span>Head Of Idea</span>
                        </div>
                        <div class="autograph-signature"><img src="assets/img/signature.png" alt=""/></div>
                    </div> */}
                </div>
            </div>
        </div>
    </section>

        <section class="service-details-wrapper">
          <div class="container">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-xl-10 col-12 pe-xl-5">
                <div class="service-details-contents">
                  <div class="contents">
                    <h2>
                      <FormattedMessage id="service1--feature-1-title" />
                    </h2>
                    <div class="service-feature-img" style={{display:'flex', justifyContent:'center'}}>
                      <img src="/img/one-stop.png" alt="" style={{width:'100%', maxWidth:'700px'}}/>
                    </div>
                    <p>
                      <FormattedMessage id="service1--feature-1-desc" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "120px" }} />
          <div class="container">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-xl-10 col-12 pe-xl-5 right">
                <div class="service-details-contents">
                  <div class="contents">
                    <h2>
                      <FormattedMessage id="service1--feature-2-title" />
                    </h2>
                    <div class="service-feature-img"  style={{display:'flex', justifyContent:'center'}}>
                      <img src="/img/secure.png" alt="" style={{width:'100%', maxWidth:'700px'}}/>
                    </div>
                    <p>
                      <FormattedMessage id="service1--feature-2-desc" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ height: "80px" }} />
        <ServiceFeatures />
        <div style={{ height: "120px" }} />
        <HowWeWork />
        <PriceTable />
        <ValueProposition />
      </Layout>
    </SimpleLocalize>
  );
}
